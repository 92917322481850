<template>
  <Comments page="cheer" :project="project"/>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Comments from "@/pages/manage/Comments";

function Component(initialize) {
  this.name = "pageManageCheer";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Comments},
  props: {
    project: Object
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  },
});
</script>

<style lang="scss" scoped>
</style>